import { useNavigate } from "react-router-dom";
import styles from "./ProjectCard.module.css";
import { PropTypes } from "prop-types";
import React from "react";

function ProjectCard({ name, url, id }) {
  const navigate = useNavigate();
  return (
    <div
      className={styles["card"]}
      onClick={() => navigate(`/projects/#${id}`)}
    >
      <h2 className={styles["card-text"]}>{name}</h2>
      <div className={styles["card-shading"]} />
      <img alt="3D Mpr" src={url} className={styles["card-img"]} />
    </div>
  );
}

ProjectCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
export default ProjectCard;
