import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
} from "@mui/material";
import styles from "./MPR.module.css";
import React, { useEffect, useState } from "react";
import { mpr } from "./components/mpr";

function MPR() {
  const [workstation, setWorkstation] = useState();
  const [axialPos, setAxialPos] = useState(0.5);
  const [organ, setOrgan] = useState("");
  const [coronalPos, setCoronalPos] = useState(0.5);
  const [sagitalPos, setSagitalPos] = useState(0.5);
  useEffect(() => {
    const canvas = document.getElementById("mpr-render-canvas");
    const tempWorkstation = new mpr(canvas);
    setWorkstation(tempWorkstation);
  }, []);

  const handleChange = (event) => {
    let organ = event.target.value;
    setOrgan(organ);
    if (organ === "skull") {
      fetch("skull.raw").then((resp) => {
        resp.arrayBuffer().then((arr) => {
          workstation.updateTexture(arr);
        });
      });
    } else if (organ === "foot") {
      fetch("foot.raw").then((resp) => {
        resp.arrayBuffer().then((arr) => {
          workstation.updateTexture(arr);
        });
      });
    }
  };

  const disposeEngine = () => {
    if (workstation) workstation.disposeEngine();
    window.removeEventListener("popstate", disposeEngine);
  };
  window.addEventListener("popstate", disposeEngine);

  const updateCoronalPos = (event, newVal) => {
    setCoronalPos(newVal);
    workstation.updateCoronalPos(newVal);
  };

  const updateAxialPos = (event, newVal) => {
    setAxialPos(newVal);
    workstation.updateAxialPos(newVal);
  };

  const updateSagitalPos = (event, newVal) => {
    setSagitalPos(newVal);
    workstation.updateSagitalPos(newVal);
  };

  return (
    <div className={styles["mpr-container"]}>
      <Alert severity="info" sx={{ boxSizing: "border-box", margin: "30px 0" }}>
        You can navigate in 3D space by clicking and dragging!
      </Alert>
      <div className={styles["workstation-container"]}>
        <div className={styles["sliders-container"]}>
          <FormControl fullWidth>
            <InputLabel id="select-organ" sx={{ color: "#18c0db" }}>
              Organ
            </InputLabel>
            <Select
              labelId="select-organ"
              value={organ}
              onChange={handleChange}
              sx={{
                border: "2px solid #18c0db",
                color: "#18c0db",
                "& .MuiSvgIcon-root": {
                  color: "#18c0db",
                },
              }}
            >
              <MenuItem value="skull">Skull</MenuItem>
              <MenuItem value="foot">Foot</MenuItem>
            </Select>
          </FormControl>
          <div className={styles["slider"]}>
            Axial Slider
            <Slider
              min={0}
              max={1}
              step={0.01}
              value={axialPos}
              onChange={updateAxialPos}
            />
          </div>
          <div className={styles["slider"]}>
            Coronal Slider
            <Slider
              value={coronalPos}
              onChange={updateCoronalPos}
              min={0}
              max={1}
              step={0.01}
            />
          </div>
          <div className={styles["slider"]}>
            Sagital Slider
            <Slider
              min={0}
              max={1}
              step={0.01}
              value={sagitalPos}
              onChange={updateSagitalPos}
            />
          </div>
        </div>
        <canvas id="mpr-render-canvas" className={styles["babylon-canvas"]} />
      </div>
    </div>
  );
}

export default MPR;
