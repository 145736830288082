import { Link } from "react-router-dom";
import styles from "./HeaderNav.module.css";
import PropTypes from "prop-types";
import React from "react";

const pages = [
  {
    pageName: "Home",
    route: "/",
  },
  {
    pageName: "Bio",
    route: "/bio",
  },
  {
    pageName: "Projects",
    route: "/projects",
  },
];
function HeaderNav({ pageId }) {
  return (
    <div className={styles["header-nav"]}>
      <div className={styles["pages-container"]}>
        {pages.map((page) => (
          <Link
            to={page.route}
            key={page.pageName}
            className={styles["nav-item"]}
            style={{ color: pageId === page.pageName ? "#18C0DB" : "#E7E7E7" }}
          >
            {page.pageName}
          </Link>
        ))}
      </div>
      <button
        className={styles["button"]}
        onClick={() =>
          window.open(
            "https://mail.google.com/mail/?view=cm&source=mailto&to=amrzayed40@gmail.com",
            "_blank"
          )
        }
      >
        Contact Me
      </button>
    </div>
  );
}

HeaderNav.propTypes = {
  pageId: PropTypes.string.isRequired,
};
export default HeaderNav;
