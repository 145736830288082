import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './Routes/Home';
import Projects from './Routes/Projects';
import Bio from './Routes/Bio';
import VolumeRender from './Routes/VolumeRender';
import MPR from './Routes/MPR';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: 'bio',
    element: <Bio />
  },
  {
    path: 'projects',
    element: <Projects />,
  },
  {
    path: 'volume-render',
    element: <VolumeRender />,
  },
  {
    path: '3d-mpr',
    element: <MPR />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>    
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
