import HeaderNav from "./components/HeaderNav";
import rootStyles from "./Root.module.css";
import { myProjcts } from "./data";
import { Box, Card, CardContent, CardMedia } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

function Projects() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      window.location.replace(hash);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  window.addEventListener("resize", function () {
    setScreenWidth(window.innerWidth);
  });
  return (
    <div className={rootStyles["section-1"]}>
      <div className={rootStyles["sec1-filter"]}>
        <div className={rootStyles["root-padding"]}>
          <HeaderNav pageId="Projects" />
        </div>
        <div style={{ height: screenWidth <= 1005 ? "50px" : "150px" }} />
        {myProjcts.map((project) => (
          <Card
            key={project.id}
            sx={{
              display: "flex",
              flexDirection: screenWidth <= 1005 ? "column" : "row",
              width: "70%",
              marginBottom: screenWidth <= 1005 ? "50px" : "100px",
              backgroundColor: "#222222",
            }}
          >
            <CardMedia
              id={project.id}
              component="img"
              sx={{ width: screenWidth <= 1005 ? "auto" : "40%" }}
              image={project.imgUrl}
              alt={project.name}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography
                  component="div"
                  variant="h5"
                  fontSize={screenWidth <= 538 ? "18px" : "32px"}
                  color="#18C0DB"
                  margin="20px 0"
                >
                  {project.name}
                </Typography>
                {project.demo.isAvailable ? (
                  <Typography marginBottom="10px">
                    <Link
                      to={project.demo.url}
                      key={project.id}
                      className={rootStyles["demo-nav"]}
                    >
                      Demo Available
                    </Link>
                  </Typography>
                ) : (
                  ""
                )}
                <Typography
                  variant="subtitle1"
                  color="white"
                  fontSize={screenWidth <= 538 ? "11px" : "18px"}
                  component="div"
                >
                  {project.description}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Projects;
