import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import styles from "./VolumeRender.module.css";
import React, { useEffect, useState } from "react";
import { volumeRender } from "./components/volumeRender";

function VolumeRender() {
  const [organ, setOrgan] = useState("");
  const [colorMap, setColorMap] = useState("winter");
  const [workstation, setWorkstation] = useState();

  useEffect(() => {
    const canvas = document.getElementById("volume-render-canvas");
    const tempWorkstation = new volumeRender(canvas);
    setWorkstation(tempWorkstation);
  }, []);

  const disposeEngine = () => {
    if (workstation) workstation.disposeEngine();
    window.removeEventListener("popstate", disposeEngine);
  };
  window.addEventListener("popstate", disposeEngine);

  const handleChange = (event) => {
    let organ = event.target.value;
    setOrgan(organ);
    if (organ === "skull") {
      fetch("skull.raw").then((resp) => {
        resp.arrayBuffer().then((arr) => {
          workstation.updateTexture(arr);
        });
      });
    } else if (organ === "foot") {
      fetch("foot.raw").then((resp) => {
        resp.arrayBuffer().then((arr) => {
          workstation.updateTexture(arr);
        });
      });
    }
  };
  const handleChangeTF = (event) => {
    let color = event.target.value;
    setColorMap(color);
    workstation.setTF(color);
  };

  return (
    <div className={styles["volume-render"]}>
      <div className={styles["container"]}>
        <Alert severity="info" sx={{ boxSizing: "border-box" }}>
          You can navigate in 3D space by clicking and dragging!
        </Alert>
        <div className={styles["controls"]}>
          <FormControl fullWidth>
            <InputLabel id="select-organ" sx={{ color: "#18c0db" }}>
              Organ
            </InputLabel>
            <Select
              labelId="select-organ"
              value={organ}
              onChange={handleChange}
              sx={{
                border: "2px solid #18c0db",
                color: "#18c0db",
                "& .MuiSvgIcon-root": {
                  color: "#18c0db",
                },
              }}
            >
              <MenuItem value="skull">Skull</MenuItem>
              <MenuItem value="foot">Foot</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="select-tf" sx={{ color: "#18c0db" }}>
              Transfr Function
            </InputLabel>
            <Select
              defaultValue="winter"
              labelId="select-tf"
              value={colorMap}
              onChange={handleChangeTF}
              sx={{
                border: "2px solid #18c0db",
                color: "#18c0db",
                "& .MuiSvgIcon-root": {
                  color: "#18c0db",
                },
              }}
            >
              <MenuItem value="winter" color="#ffffff">
                Winter
              </MenuItem>
              <MenuItem value="cool">Cool</MenuItem>
            </Select>
          </FormControl>
        </div>
        <canvas id="volume-render-canvas" className="babylon-canvas" />
      </div>
    </div>
  );
}

export default VolumeRender;
