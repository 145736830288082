import HeaderNav from "./components/HeaderNav";
import rootStyles from "./Root.module.css";
import styles from "./Home.module.css";
import ProjectCard from "./components/ProjectCard";
import React, { useRef, useState } from "react";
import { myLinks, myProjcts, mySkills } from "./data";

function Home() {
  const [skills, setSkills] = useState(mySkills);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const ref = useRef(null);

  window.addEventListener("resize", function () {
    setScreenWidth(window.innerWidth);
  });
  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleChangeSkill = (clickedSkill) => {
    let tempSkills = [...skills];
    tempSkills.forEach((skill) => {
      if (skill.skill === clickedSkill) {
        skill.active = true;
      } else {
        skill.active = false;
      }
    });
    setSkills(tempSkills);
  };

  return (
    <div className={rootStyles["section-1"]}>
      <div className={rootStyles["sec1-filter"]}>
        <div className={rootStyles["root-padding"]}>
          <HeaderNav pageId="Home" />
        </div>
        <div className={styles["intro-container"]}>
          <img
            alt="amr zayed"
            src="/AmrZayed.png"
            className={styles["amr-img"]}
          />
          <div className={styles["title"]}>
            <h3 className={styles["eng-text"]}>Eng.</h3>
            <h1 className={styles["name-text"]}>Amr Zayed</h1>
          </div>
        </div>
        <div className={styles["icons-container"]}>
          {myLinks.map((link) => (
            <img
              key={link.link}
              className={styles["icon"]}
              alt={link.icon}
              src={link.icon}
              onClick={() => window.open(link.link, "_blank")}
            />
          ))}
        </div>
        <button className={styles["learn-more"]} onClick={handleScroll}>
          LEARN MORE
          <img alt="scroll" src="/double-arrow-down.svg" />
        </button>
        <div className={styles["about-me"]} ref={ref}>
          <h1>About Me</h1>
          <main className={styles["animation-container"]}>
            <p>I&apos;m a</p>
            <section className={styles["animation"]}>
              <div>
                <div>Frontend Engineer</div>
              </div>
              <div>
                <div>3D Web Specialist</div>
              </div>
              <div>
                <div>Image Processing Eng.</div>
              </div>
              <div>
                <div>UI/UX designer</div>
              </div>
              <div>
                <div>Branding Specialist</div>
              </div>
              <div>
                <div>Frontend Engineer</div>
              </div>
            </section>
          </main>
          <p className={styles["about-me-description"]}>
            As a versatile engineer with expertise in{" "}
            <span className={styles["text-style"]}>
              image processing, computer vision, 3D web visualization, demo
              creation, frontend engineering, UI/UX design, and branding
            </span>
            , I am dedicated to innovating at the intersection of
            <span className={styles["text-style"]}> technology and design</span>
            . I excel in{" "}
            <span className={styles["text-style"]}> extracting </span>
            insights from{" "}
            <span className={styles["text-style"]}>visual data</span>, creating
            immersive 3D web experiences, and crafting{" "}
            <span className={styles["text-style"]}>
              user-friendly interfaces{" "}
            </span>
            that reflect strong{" "}
            <span className={styles["text-style"]}>brand identities</span>. My
            passion lies in leveraging these skills to drive{" "}
            <span className={styles["text-style"]}>meaningful change</span> in
            an ever-evolving digital landscape.
          </p>
        </div>
        <div className={styles["tools-section"]}>
          <h1 className={styles["tools-title"]}>I Use</h1>
          <div className={styles["tools-container"]}>
            <div className={styles["skills"]}>
              {skills.map((skill) => (
                <button
                  key={skill.skill}
                  onClick={() => handleChangeSkill(skill.skill)}
                  className={styles["skill"]}
                  style={
                    skill.active
                      ? {
                          border: "3px solid #18C0DB",
                          fontSize: screenWidth <= 830 ? "20px" : "32px",
                        }
                      : {}
                  }
                >
                  {skill.skill}
                </button>
              ))}
            </div>
            <div className={styles["tools"]}>
              {skills.map((skill) =>
                skill.active
                  ? skill.tools.map((tool) => (
                      <div key={tool.name} className={styles["tool"]}>
                        <img
                          className={styles["tool-img"]}
                          alt={tool.name}
                          src={tool.url}
                        />
                        <h3 className={styles["tool-txt"]}>{tool.name}</h3>
                      </div>
                    ))
                  : null
              )}
            </div>
          </div>
        </div>
        <h1> Selected Projects</h1>
        <div className={styles["projects-container"]}>
          {myProjcts.map((project) => (
            <ProjectCard
              key={project.name}
              id={project.id}
              name={project.name}
              url={project.imgUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
