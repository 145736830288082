export const mySkills = [
    {
        skill: 'Tools',
        active: true,
        tools: [
            {
                name: 'React.js',
                url: '/ToolsLogos/react.svg'
            },{
                name: 'Angular',
                url: '/ToolsLogos/angular.svg'
            },{
                name: 'WebGL',
                url: '/ToolsLogos/webgl.svg'
            },{
                name: 'Babylon.js',
                url: '/ToolsLogos/Babylon.svg'
            },{
                name: 'Git',
                url: '/ToolsLogos/Git.svg'
            },{
                name: 'Jest.js',
                url: '/ToolsLogos/Jest JS.svg'
            },
        ]
    },{
        skill: 'Prog. Languages',
        active: false,
        tools: [
            {
                name: 'JavaScript',
                url: '/ToolsLogos/JavaScript.svg'
            },{
                name: 'TypeScript',
                url: '/ToolsLogos/Typescript.svg'
            },{
                name: 'HTML',
                url: '/ToolsLogos/HTML5.svg'
            },{
                name: 'CSS',
                url: '/ToolsLogos/CSS3.svg'
            },{
                name: 'Python',
                url: '/ToolsLogos/Python.svg'
            },{
                name: 'MATLAB',
                url: '/ToolsLogos/matlab.svg'
            },
        ]
    },{
        skill: 'Applications',
        active: false,
        tools: [
            {
                name: 'Adobe XD',
                url: '/ToolsLogos/adobexd.svg'
            },{
                name: 'DaVinci Resolve',
                url: '/ToolsLogos/DaVinci_Resolve.svg'
            },{
                name: 'Adobe AE',
                url: '/ToolsLogos/adobe_aftereffects.svg'
            },{
                name: 'Adobe Illustrator',
                url: '/ToolsLogos/adobe_illustrator.svg'
            },{
                name: 'Adobe Photoshop',
                url: '/ToolsLogos/adobe_photoshop.svg'
            },
        ]
    },
]
export const myProjcts = [
    {
        id: "vol-rendering",
        name: "Volume Rendering",
        demo: {
            isAvailable: true,
            url: '/volume-render'
        },
        imgUrl: "/VolumeRendering.png",
        description: "The developed volumetric rendering algorithm in Babylon.js and WebGL achieved significant technical milestones. Key challenges included efficiently handling large data files by breaking them into manageable chunks and implementing data compression for reduced bandwidth usage. Optimizing the algorithm for real-time rendering by leveraging WebGL's parallel processing capabilities resulted in remarkable performance. Additionally, a flexible transfer function allowed users to assign colors to grayscale levels, enabling vivid and customizable volume renders, combining technical complexity with artistic creativity."
    },{
        id: "twitter-clone",
        name: `Twitter Clone`,
        demo: {
            isAvailable: false,
            url: ''
        },
        imgUrl: "/TwitterReplica.png",
        description: "I served as a front-end engineer on a collaborative project with 15 engineers, replicating Twitter's functionalities. Communication and coordination were vital in this ambitious project. My main responsibility was designing and implementing a responsive user interface that resembled Twitter's, and I worked closely with the backend team for reliability and performance. Together, we built a robust architecture capable of handling a social media platform's demands. Our Twitter replica project demonstrated both technical skills and effective teamwork, resulting in a high-quality, scalable, and enjoyable user experience."
    },{
        id: "3d-mpr",
        name: "3D MPR",
        demo: {
            isAvailable: true,
            url: '/3d-mpr'
        },
        imgUrl: "/3D MPR.png",
        description: "I created a groundbreaking medical imaging application called 3D Multi-Planar Reconstruction (MPR) using React.js, Babylon.js, and WebGL. This innovative tool provides an immersive experience, allowing dynamic manipulation of medical data in axial, coronal, and sagittal planes. It also enables users to rotate and navigate through 3D slices, revolutionizing medical visualization and improving healthcare professionals' diagnostic and research capabilities."
    },{
        id: "IP-concepts",
        name: "Image-processing Concepts",
        demo: {
            isAvailable: false,
            url: ''
        },
        imgUrl: "/IP-Concepts.png",
        description: "I undertook a comprehensive image-processing project covering various techniques and aspects. It included sections on zooming with interpolation, image enhancement with contrast stretching and histogram equalization, a range of filters, Laplacian of Gaussian (LOG) for detail detection, and frequency domain processing to remove noise. This project demonstrated the versatility and complexity of image processing techniques I used to improve image quality and extract valuable information."
    }
]
export const myLinks = [
    {
        icon: '/twitter.svg',
        link: 'https://twitter.com/AmrZayeed'
    },{
        icon: '/linkedin.svg',
        link: 'https://www.linkedin.com/in/amr-zayed40/'
    },{
        icon: '/github.svg',
        link: 'https://github.com/amr-zayed'
    },{
        icon: '/gmail.svg',
        link: 'https://mail.google.com/mail/?view=cm&source=mailto&to=amrzayed40@gmail.com'
    },
]