import HeaderNav from "./components/HeaderNav";
import rootStyles from "./Root.module.css";
import styles from "./Bio.module.css";
import React from "react";

function Bio() {
  return (
    <div className={rootStyles["section-1"]}>
      <div className={rootStyles["sec1-filter"]}>
        <div className={rootStyles["root-padding"]}>
          <HeaderNav pageId="Bio" />
        </div>
        <div className={styles["section-2"]}>
          <img
            className={styles["full-body-img"]}
            alt="amr"
            src="/FullBody.png"
          />
          <div className={styles["about-me"]}>
            <h1>About Me</h1>
            <p className={styles["about-me-description"]}>
              As an engineer with a diverse skill set encompassing
              <span className={styles["text-style"]}>
                {" "}
                image processing, computer vision, 3D web visualization, demo
                creation, frontend engineering, UI/UX design, and branding
                expertise
              </span>
              , I am committed to pushing the boundaries of technology and
              design to create innovative solutions that solve real-world
              problems. With a deep passion for computer vision and image
              processing, I have
              <span className={styles["text-style"]}>
                {" "}
                developed algorithms and applications
              </span>{" "}
              that can extract meaningful information from complex visual data,
              enabling organizations to make data-driven decisions. My{" "}
              <span className={styles["text-style"]}> proficiency</span> in 3D
              web visualization allows me to{" "}
              <span className={styles["text-style"]}>
                {" "}
                transform abstract concepts
              </span>{" "}
              into interactive and immersive experiences, opening up new
              possibilities for data representation and{" "}
              <span className={styles["text-style"]}> user engagement</span>.
              <br />
              <br />
              In addition to my technical capabilities, I am dedicated to
              creating
              <span className={styles["text-style"]}>
                {" "}
                exceptional user experiences
              </span>
              through frontend engineering and{" "}
              <span className={styles["text-style"]}> UI/UX design</span>. I
              understand that technology is most effective when it is{" "}
              <span className={styles["text-style"]}>
                {" "}
                user-friendly and aesthetically pleasing
              </span>
              . Leveraging my skills, I have designed and developed user
              interfaces that not only
              <span className={styles["text-style"]}>
                {" "}
                prioritize functionality and usability
              </span>
              but also{" "}
              <span className={styles["text-style"]}>
                reflect a strong brand identity
              </span>
              . My branding expertise ensures that every project I undertake
              aligns with the
              <span className={styles["text-style"]}>
                {" "}
                client&apos;s vision and values
              </span>
              , leaving a lasting and impactful impression. In a rapidly
              evolving digital landscape, I am driven by the belief that the{" "}
              <span className={styles["text-style"]}>
                fusion of cutting-edge technology and design excellence
              </span>{" "}
              can drive meaningful change, and I am excited to continue my
              journey as{" "}
              <span className={styles["text-style"]}>
                an engineer and designer
              </span>{" "}
              at the intersection of these fields.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bio;
