import { Scene,Engine, Vector3, MeshBuilder, ShaderMaterial, RawTexture3D, ArcRotateCamera, Color3, StandardMaterial, Mesh, RawTexture, HemisphericLight } from "babylonjs";

export class volumeRender{
    constructor(canvas){
      this.engine = new Engine(canvas, true);
      this.engine.setHardwareScalingLevel = 10.0;
      this.scene = this.CreateScene();

      this.engine.runRenderLoop(()=>{
        this.backFaceMaterial.setArray3('cameraPos', this.camera.position);

        if(!this.scene.paused){
          this.scene.render();
        }
      })
    }

    CreateCamera() {
      // creates the ArcRotateCamera with radius 1 and sets its target to center of volume cube center
      const camera = new ArcRotateCamera('Camera', Math.PI / 2, Math.PI, 1, new Vector3(0.5, 0.5, 0.5), this.scene);
      // enable maximum rotations in all axis
      camera.lowerAlphaLimit = -1000;
      camera.upperAlphaLimit = 1000;
      camera.lowerBetaLimit = -1000;
      camera.upperBetaLimit = 1000;
      camera.lowerRadiusLimit = 0.02;
      camera.allowUpsideDown = true;
      // disable collision with other objects in scene
      camera.checkCollisions = false;
      // disable move after mouse release
      camera.speed = 0.0;
      // decreasing zooming speed
      camera.wheelPrecision = 100;
      // minimum z distnace from center of target
      camera.minZ = 0.1;
      // attaching the camera to the scene
      camera.attachControl();
      return camera;
    }

    CreateScene() {
      const scene = new Scene(this.engine);
      
      scene.clearColor = Color3.Black;
      scene.ambientColor = new Color3(0.3, 0.3, 0.3);

      this.camera = this.CreateCamera();
      
      // creates light to light the scene
      const hemiLight = new HemisphericLight('hemiLight', new Vector3(0, 1, 0), scene);
      hemiLight.intensity = 0.5;

      this.backFace = MeshBuilder.CreateBox('backSide', {
        size: 1,
        sideOrientation: Mesh.BACKSIDE,
      }, scene);
      // giving the geometry a temporary material
      this.backFace.material = new StandardMaterial('tempMat', scene);
      // making the material transparent
      this.backFace.material.alpha = 0;
      // setting the center of the cube the same as the target
      this.backFace.position = new Vector3(0.5, 0.5, 0.5);
      // creating the custom shader
      this.backFaceMaterial = new ShaderMaterial(
        'backFaceShader',
        scene,
        '/Shaders/backFace',
        {
          needAlphaBlending: true,
          needAlphaTesting: true,
          attributes: ['position'],
          uniforms: ['world', 'view', 'projection', 'alphaCorrection', 'cameraPos'],
          samplers: ['textureSampler', 'transferFunction'],
        },
      );

      // initializing the transfer function texture
      this.setTF('winter');

      this.backFaceMaterial.setArray3('cameraPos', this.camera.position);
      this.backFaceMaterial.setFloat('smoothnessFactor', 0.6);
      this.backFaceMaterial.setFloat('alphaCorrection', 2);
      return scene 
    }
    
    setTF(map){
      if (map==='winter'){
        this.winterTF();
      }else if (map==='cool'){
        this.coolTF()
      }
    }
    winterTF() {
      // intializing an RGBA 1D array transfer texture
      let initArr = []
      for (let i=0;i<256;i+=1){
        if (i>50 && i<70){
          initArr.push(0)
          initArr.push(0)
          initArr.push(255)
          initArr.push(105)
        }
        else if (i>=70 && i<100 ){
          initArr.push(0)
          initArr.push(251)
          initArr.push(130)
          initArr.push(105)
        }else{
          initArr.push(0)
          initArr.push(0)
          initArr.push(0)
          initArr.push(0)
        }
      }
      this.transferTexture = new RawTexture(
        new Uint8Array(initArr),
        256,
        1,
        Engine.TEXTUREFORMAT_RGBA,
        this.scene,
      );
      // making it updatable
      this.transferTexture.needsUpdate = true;
      // passing the texture to the custom volume render shader
      this.backFaceMaterial.setTexture('transferFunction', this.transferTexture);
    }
    coolTF() {
      // intializing an RGBA 1D array transfer texture
      let initArr = []
      for (let i=0;i<256;i+=1){
        if (i>50 && i<70){
          initArr.push(4)
          initArr.push(251)
          initArr.push(255)
          initArr.push(105)
        }
        else if (i>=70 && i<100 ){
          initArr.push(251)
          initArr.push(4)
          initArr.push(255)
          initArr.push(105)
        }else{
          initArr.push(0)
          initArr.push(0)
          initArr.push(0)
          initArr.push(0)
        }
      }
      this.transferTexture = new RawTexture(
        new Uint8Array(initArr),
        256,
        1,
        Engine.TEXTUREFORMAT_RGBA,
        this.scene,
      );
      // making it updatable
      this.transferTexture.needsUpdate = true;
      // passing the texture to the custom volume render shader
      this.backFaceMaterial.setTexture('transferFunction', this.transferTexture);
    }

    updateTexture(dataArray){
      var newTex = new RawTexture3D(new Uint8Array(dataArray), 256, 256, 256, Engine.TEXTUREFORMAT_LUMINANCE, this.scene)
      newTex.wrapU = 0;
      newTex.wrapV = 0;
      newTex.wrapR = 0;
      newTex.updateSamplingMode(11);
      this.backFaceMaterial.setTexture("textureSampler", newTex);
      this.backFaceMaterial.backFaceCulling = false;
      this.backFace.material = this.backFaceMaterial;
    }

    disposeEngine() {
      // gets rid of the engine to avoid unneeded computation when called
      this.engine.dispose();
    }
  }